.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Set height to 100% of the viewport height */
}

.loading-animation {
  width: 200px;
  height: 200px;
  border: 8px solid black;
  border-radius: 50%;
  border-top: 8px solid transparent;
  animation: spin 1s ease-in-out infinite; /* Increase the duration to 2 seconds */
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
